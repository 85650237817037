import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { xApiKeyMyAccount, xChatImageMyAccount } from '@core/interceptors/auth.interceptor';
import { IMessage } from '@core/interfaces/chat.interface';
import { ChatStateBadgetPipe } from '@core/pipes/chat-state-badget.pipe';
import { environment } from '@environments/environment';
import { Observable, delay, map, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private http = inject(HttpClient);
  private pipe = inject(DatePipe);
  private badgetPipe = inject(ChatStateBadgetPipe);

  private getConversationEndpoint: string = "api/chat/conversations"
  private getMessagesEndpoint: string = "api/chat/messages"
  private chatFileEndpoint: string = "api/chat/resources"
  // private treatmentProgressEndpoint: string = "api/clients/resources/treatment-progress"
  private treatmentProgressEndpoint: string = "api/customers/resources/treatment-progress"

  progress: any = [];

  sendMessage(message, chatId: string){
    console.log('Mensaje', message)
    console.log('ChatId', chatId)
    const finalMessage = {...message, content: message.content.split("\n").join("<br><br>")}
    return this.http.post(`${environment.apiMyAccountUrl}/api/chat/messages/${chatId}/send_message`, finalMessage, { context: xApiKeyMyAccount() })
  }

  getConversations(page: number, quantity: number, status: string, treatmentId: string, searchFilter: any = null){
    return this.http.get(`${environment.apiMyAccountUrl}/${this.getConversationEndpoint}/parameters?status=${status}&treatment-id=${treatmentId}&size=${quantity}&page=${page}&name-or-id=${searchFilter ? searchFilter : ''}`, {
      context: xApiKeyMyAccount()
    }).pipe(
      map((data: any) => {
        data.result.forEach(c => {
          c['date'] = `${this.pipe.transform(c.lastActivityDate, 'dd/MM/yyyy', 'UTC-6')}`;
          c['hour'] = `${this.pipe.transform(c.lastActivityDate, 'HH:mm', 'UTC-6')}`;
          c['noLeidoPorDoctor'] = this.badgetPipe.transform(c['hasUnreadMessagesByDoctor']);
          c['badgetClass'] = `${c['hasUnreadMessagesByDoctor'] ? 'choiz-badget orange-badget' : 'choiz-badget gray-badget'}`;
          c['badgetClassSeg'] = `${c['hasUnreadMessagesByDoctor'] ? 'choiz-badget text-col orange-badget' : 'choiz-badget text-col gray-badget'}`;
        })
        return data;
      })
    )
  }

  getSelectedConversation(id:string){
    return this.http.get(`${environment.apiMyAccountUrl}/api/chat/messages/${id}?size=2000`, { context: xApiKeyMyAccount() })
  }

  getMessages(id: string){
    return this.http.get(`${environment.apiMyAccountUrl}/${this.getMessagesEndpoint}/${id}?size=10&page=0`, { context: xApiKeyMyAccount() }).pipe(
      map(
        (data:any) => {
          const reversedMessages = {...data}
          reversedMessages.content = data.content.reverse()
          return reversedMessages
        }
      )
    )
  }

  getItems(id: string, page=0, itemsPerPage=20, totalItems: number = 200){
    return this.http.get(`${environment.apiMyAccountUrl}/${this.getMessagesEndpoint}/${id}?size=${itemsPerPage}&page=${page}`, { context: xApiKeyMyAccount() }).pipe(
      map(
        (data:any) => {
          //const reversedMessages = {...data.results}
          //const arrayOfObjects = Object.values(reversedMessages);
          //return arrayOfObjects.reverse()
          //return arrayOfObjects
          console.log('Service', data.results)
          return data.results
        }
      )
    )
  }

  getItemsMock(id: string, page=1, itemsPerPage=20, totalItems: number = 200){
    const startIndex = (page-1)*itemsPerPage;
    const endIndex = startIndex+itemsPerPage;
    const items = [];
    console.log('Servicio', {startIndex, endIndex});
    for(let i = startIndex; i < endIndex; i++){
      if(i < totalItems){
        items.push({
          "id": "6639406d3baadc6860b8e80f",
          "createdDate": "2024-05-06T20:41:17.316",
          "content": "hola" +i,
          "read": false,
          "createdBy": "COMMON_USER"
      });
      }
    }
    return of(items).pipe(delay(500));

    // return this.http.get(`assets/data/mocks/conversation.json`).pipe(
    //   map((res: any) => {
    //     console.log('Servicio chat', res.results);
    //      return res.results
    //   })
    // )

   }

  getChatFile(fileName: string){
    return this.http.get(`${environment.apiMyAccountUrl}/${this.chatFileEndpoint}`, { context: xChatImageMyAccount(), headers: {
      'file-name': fileName
    }, responseType: 'blob'}).pipe(
      tap(
        image => { return image }
      )
    )
  }

  uploadChatFile(file:File){
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.http.post(`${environment.apiMyAccountUrl}/${this.chatFileEndpoint}`, formData, { context: xChatImageMyAccount() })
  }

  getTreatmentProgress(customer_id: number, treatment_id: number){
    return this.http.get(`${environment.apiMyAccountUrl}/${this.treatmentProgressEndpoint}?customer_id=${customer_id}&treatment_id=${treatment_id}`, { context: xApiKeyMyAccount() })
    .pipe(
      map((progress: any) => {
        return progress.result.map(p => {
          const _date = p.customCreatedDate.split('T')[0];
          const _time = p.customCreatedDate.split('T')[1].split('.')[0];
          let __date  = new Date(_date);
          const _dateFormat = __date.toLocaleDateString("es-MX", { year: 'numeric', month: 'long', day: 'numeric' });
          const newProgress = {
            date: _dateFormat,
            time: _time,
            images: p.imageUrlList
          }
          return newProgress
        })
      })
    )
  }
}
