import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'solicitud',
    standalone: true
})
export class DataSolicitudPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if(Array.isArray(value)){
      let res = '';
      value.map(val =>  {
        if(Array.isArray(val)){
          res += val[0]+','
        } else {
          res += val+','
        }
      });
      res = res.substring(0, res.length - 1);
      return res;
    }
    if(typeof value === 'object'){
      let res = '';
      if(value && value.hasOwnProperty('name')){
        res = value.name;
      }
      if(value && value.hasOwnProperty('brand')){
        res = value.brand;
      }
      if(value && value.hasOwnProperty('value')){
        res = value.value;
      }
      return res;
    }
    if(typeof value === 'string' || typeof value === 'number'){
      return value;
    }
    return null;
  }

}
