import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fechaHora',
    standalone: true
})
export class FechaHoraPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      return `${this.genFecha(value)}`;
    } else {
      return null;
    }
  }

  genFecha(value: string): String{
    let dateParts = value.split('T');
    let fecha: string;
    let fechaTemp = dateParts[0];
    let datePartsTemp = fechaTemp.split('-');
    let horaPart = dateParts[1];
    let horaPartTemp = horaPart.split(':');
    fecha = `${datePartsTemp[2]}-${datePartsTemp[1]}-${datePartsTemp[0]} ${horaPartTemp[0]}:${horaPartTemp[1]}`;
    return fecha;
  }

}
