import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'chatSender',
    standalone: true
})
export class ChatSenderPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch(value){
      case 'ADMIN_USER': return 'doctor ml-auto'; break;
      case 'DOCTOR_USER': return 'doctor ml-auto'; break;
      case 'AUTOMATIC': return 'doctor ml-auto'; break;
      case 'COMMON_USER': return 'customer'; break;
      default: return value
    }
  }

}
